<template>
  <tr
    :class="[
      'items-table__tr',
      !checkIsTranslated() ? 'items-table__tr--color' : '',
    ]"
  >
    <td class="items-table__td">
      <div
        class="tooltip-info"
        :data-tooltip="
          checkIsTranslated()
            ? 'Dane są przetłumaczone na aktualny język.'
            : 'Dane w polskiej wersji zostały zmienione i nie są przetłumaczone na aktualny język.'
        "
      >
        <icon
          icon="info"
          :icon-classes="
            'button__icon button__icon--left ' +
            `${
              !checkIsTranslated() ? 'button__icon--blue' : 'button__icon--gray'
            }`
          "
        />
      </div>
    </td>
    <td class="items-table__td">
      <label :for="'field-translation-' + indexTranslation" class="checkbox">
        <input
          :id="'field-translation-' + indexTranslation"
          :ref="'field-translation-' + indexTranslation"
          class="checkbox__input"
          type="checkbox"
          @change="checkTranslation()"
        />
        <span class="checkbox__checkmark"></span>
      </label>
    </td>
    <td class="items-table__td">
      {{ indexTranslation + 1 }}
    </td>
    <td class="items-table__td items-table__td--w30 items-table__td--vtop">
      <InputSimple
        v-if="translation.field_type == 'input'"
        :object="getProductPolish()"
        :label="translation.name"
        :fieldName="translation.field_name"
        type="text"
        :disabled="true"
      ></InputSimple>
      <EditorForm
        v-if="translation.field_type == 'editor'"
        :fieldName="translation.field_name"
        :object="getProductPolish()"
        :readonly="true"
        :label="translation.name"
      >
      </EditorForm>
    </td>

    <td class="items-table__td items-table__td--w30 items-table__td--vtop">
      <InputSimple
        v-if="translation.field_type == 'input'"
        :object="getProduct()"
        :disabled="true"
        label="Tłumaczenie"
        :fieldName="translation.field_name"
        type="text"
      ></InputSimple>
      <EditorForm
        v-if="translation.field_type == 'editor'"
        :fieldName="translation.field_name"
        :object="getProduct()"
        :readonly="true"
        label="Tłumaczenie"
      >
      </EditorForm>
    </td>
    <td class="items-table__td items-table__td--w30 items-table__td--vtop">
      <InputSimple
        v-if="translation.field_type == 'input'"
        :object="translation"
        label="Tłumaczenie ai"
        fieldName="value"
        type="text"
        @changes="changedField"
        :productChange="false"
      ></InputSimple>
      <EditorForm
        v-if="translation.field_type == 'editor'"
        fieldName="value"
        :object="translation"
        label="Tłumaczenie ai"
        :productChange="false"
        @changes="changedField"
      >
      </EditorForm>
    </td>
    <td class="items-table__td items-table__td--small">
      <button
        class="button button--small button--icon button--blue"
        @click="acceptTranslation(translation.value, translation.field_name)"
        v-if="!buttonAccept"
        :style="{ display: translation.value ? 'flex' : 'none' }"
      >
        <icon
          icon="check"
          icon-classes="button__icon button__icon--left button__icon--blue"
        />
        <span class="button__text">Zaakceptuj</span>
      </button>
      <button class="button button--small button--icon button--green" v-else>
        <span class="button__text">Zaakceptowane</span>
      </button>
    </td>
    <td class="items-table__td items-table__td--small">
      <button
        class="button button--small button--icon button--blue-dark"
        @click="getTranslation()"
        :style="{ display: buttonTranslationAi ? 'none' : 'flex' }"
      >
        <icon
          icon="plus"
          icon-classes="button__icon button__icon--left button__icon--white"
        />
        <span class="button__text">Tłumaczenie AI</span>
      </button>
    </td>
  </tr>
</template>
<script>
import InputSimple from "./forms/InputSimple.vue";
import Icon from "./../parts/Icon";
import EditorForm from "./forms/EditorForm";

import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return { buttonTranslationAi: false, buttonAccept: false };
  },
  props: {
    translation: {
      type: Object,
      required: true,
    },
    productPolish: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    indexTranslation: {
      type: Number,
    },
  },
  components: {
    InputSimple,
    Icon,
    EditorForm,
  },
  methods: {
    changeCheckMark(value) {
      this.$refs["field-translation-" + this.indexTranslation].checked = value;
    },
    changeButtons() {
      this.buttonTranslationAi = true;
      this.buttonAccept = false;
      this.$refs["field-translation-" + this.indexTranslation].checked = false;
      // this.$emit("unCheckCheckbox", this.indexTranslation);
    },
    checkTranslation() {
      if (
        this.$refs["field-translation-" + this.indexTranslation].checked ==
        false
      ) {
        this.$emit("unCheckCheckbox", this.indexTranslation);
      }
      this.$emit(
        "checkTranslation",
        this.indexTranslation,
        this.$refs["field-translation-" + this.indexTranslation].checked
      );
    },
    checkIsTranslated() {
      if (this.translation.deep_1 == "attributes") {
        return this.product.attributes[this.translation.deep_1_index]
          .is_translated_option_value_string;
      } else if (
        this.translation.deep_1 == "variants" &&
        this.translation.deep_2 == "attributes"
      ) {
        return this.product.variants[this.translation.deep_1_index].attributes[
          this.translation.deep_2_index
        ].is_translated_option_value_string;
      } else if (
        this.translation.deep_1 == "variants" &&
        !this.translation.deep_2
      ) {
        return this.product.variants[this.translation.deep_1_index][
          "is_translated_" + this.translation.field_name
        ];
      } else {
        return this.product["is_translated_" + this.translation.field_name];
      }
    },
    getProductPolish() {
      if (this.translation.deep_1 == "attributes") {
        return this.productPolish.attributes[this.translation.deep_1_index];
      } else if (
        this.translation.deep_1 == "variants" &&
        this.translation.deep_2 == "attributes"
      ) {
        return this.productPolish.variants[this.translation.deep_1_index]
          .attributes[this.translation.deep_2_index];
      } else if (
        this.translation.deep_1 == "variants" &&
        !this.translation.deep_2
      ) {
        return this.productPolish.variants[this.translation.deep_1_index];
      } else {
        return this.productPolish;
      }
    },
    getProduct() {
      if (this.translation.deep_1 == "attributes") {
        return this.product.attributes[this.translation.deep_1_index];
      } else if (
        this.translation.deep_1 == "variants" &&
        this.translation.deep_2 == "attributes"
      ) {
        return this.product.variants[this.translation.deep_1_index].attributes[
          this.translation.deep_2_index
        ];
      } else if (
        this.translation.deep_1 == "variants" &&
        !this.translation.deep_2
      ) {
        return this.product.variants[this.translation.deep_1_index];
      } else {
        return this.product;
      }
    },
    changedField(fieldName, object) {
      this.$set(object, "is_translated_" + fieldName, 0);
      this.buttonTranslationAi = false;
      this.buttonAccept = false;
    },
    acceptTranslation(text, fieldName) {
      let object = this.getProduct();
      this.$set(object, fieldName, text);
      this.$set(object, "is_translated_" + fieldName, 1);
      this.$root.$emit("productIsChanged");
      this.buttonAccept = true;
    },
    getTranslation() {
      let translation = this.getProductPolish()[this.translation.field_name];
      var params = {
        translations: [translation],
        language: this.product.locale,
      };
      axios
        .post(API_BASE_URL + "/translations", params)
        .then((response) => {
          this.$set(this.translation, "value", response.data[0].text);
          this.buttonTranslationAi = true;
          this.buttonAccept = false;
          this.$refs[
            "field-translation-" + this.indexTranslation
          ].checked = false;
          this.$emit("unCheckCheckbox", this.indexTranslation);
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
  },
};
</script>
