<template>
  <div class="content products-list">
    <Header
      v-show="products.length"
      title="Lista wariantów i produktów"
      :subheading="`${products.total} 
          ${$options.filters.numeralsText(
            products.total,
            'wariant/produktów',
            'warianty/produkty',
            'wariantów/produktów'
          )}`"
    ></Header>
    <div class="content__body">
      <content-top
        :addButton="true"
        changeTextChangeButton="Ukryj filtry"
        textChangeButton="Pokaż filtry"
        :show="showFilters"
        @getObjects="getProducts"
        @addObject="addProduct()"
      ></content-top>
      <Filters
        v-if="showFilters"
        :options="dictionaries"
        :filters="filters"
        @getObjects="getProducts"
        @searchObjects="searchProducts"
      ></Filters>

      <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
        <div
          v-if="products.data.length > 0"
          class="d-flex content-top__icons-wrap"
        >
          <button
            class="button-icon button-icon--medium"
            @click.prevent="toggleStatus()"
            :disabled="selectedProducts < 1"
            data-tooltip="Zmiana statusu"
          >
            <icon icon="on" icon-classes="icon--grey" />
          </button>
        </div>
        <spim-loader></spim-loader>
        <div
          v-if="products.data.length > 0"
          class="content content--products-list"
        >
          <table class="items-table">
            <thead class="items-table__thead">
              <tr class="items-table__tr">
                <th class="items-table__th">
                  <label for="check-product-toggle" class="checkbox">
                    <input
                      id="check-product-toggle"
                      class="checkbox__input"
                      type="checkbox"
                      v-model="toggleSelectProducts"
                    />
                    <span class="checkbox__checkmark"></span>
                  </label>
                </th>
                <th class="items-table__th">
                  <div
                    @click.prevent="sortObjects()"
                    class="items-table__sort"
                    :class="{ 'is-active': orderBy == 'id' }"
                    order-by="id"
                    order="desc"
                  >
                    Id
                    <icon icon="sort" icon-classes="items-table__sort-icon" />
                  </div>
                </th>
                <th class="items-table__th">
                  <div
                    @click.prevent="sortObjects()"
                    class="items-table__sort"
                    :class="{ 'is-active': orderBy == 'symbol' }"
                    order-by="symbol"
                    order="desc"
                  >
                    Symbol
                    <icon icon="sort" icon-classes="items-table__sort-icon" />
                  </div>
                </th>
                <th class="items-table__th">
                  <div
                    @click.prevent="sortObjects()"
                    class="items-table__sort"
                    :class="{ 'is-active': orderBy == 'status' }"
                    order-by="status"
                    order="desc"
                  >
                    Status
                    <icon icon="sort" icon-classes="items-table__sort-icon" />
                  </div>
                </th>
                <th class="items-table__th">Zdjęcie</th>
                <th class="items-table__th items-table__th--expand">Nazwa</th>
                <th class="items-table__th items-table__th--nowrap">Alerty</th>
                <th class="items-table__th items-table__th--nowrap">
                  Produkty
                </th>
                <th class="items-table__th">Status synch.</th>

                <th class="items-table__th">
                  <div
                    @click.prevent="sortObjects()"
                    class="items-table__sort"
                    :class="{ 'is-active': orderBy == 'updated_at' }"
                    order-by="updated_at"
                    order="desc"
                  >
                    Modyfikacja
                    <icon icon="sort" icon-classes="items-table__sort-icon" />
                  </div>
                </th>
                <th class="items-table__th">Język</th>
                <th class="items-table__th p-0 text-right">Akcje</th>
              </tr>
            </thead>

            <tbody class="items-table__tbody">
              <!-- item -->
              <template v-for="product in products.data">
                <tr class="items-table__tr" v-bind:key="product.id">
                  <td
                    class="items-table__td items-table__td--not-text d-none d-xl-table-cell"
                  >
                    <label
                      :for="'check-product-' + product.id"
                      class="checkbox"
                    >
                      <input
                        :id="'check-product-' + product.id"
                        class="checkbox__input"
                        type="checkbox"
                        :value="product.id"
                        v-model="selectedProducts"
                      />
                      <span class="checkbox__checkmark"></span>
                    </label>
                  </td>
                  <td class="items-table__td">
                    <div class="form-box__mobile-element">Id</div>
                    {{ product.id }}
                  </td>

                  <td class="items-table__td">
                    <div class="form-box__mobile-element">Symbol</div>
                    {{ product.symbol ? product.symbol : "-" }}
                  </td>

                  <td class="items-table__td items-table__td--not-text">
                    <div class="form-box__mobile-element">Status</div>
                    <div
                      :class="[
                        'm-auto',
                        'items-table__status',
                        {
                          'items-table__status--active': product.status == '1',
                        },
                      ]"
                    ></div>
                  </td>
                  <td class="items-table__td">
                    <div class="form-box__mobile-element">Zdjecie</div>
                    <div>
                      <img
                        :src="
                          getApiPath(
                            product.main_photo ? product.main_photo.path : null
                          )
                        "
                      />
                    </div>
                  </td>
                  <td class="items-table__td items-table__td--expand">
                    <div class="form-box__mobile-element">Nazwa</div>
                    <router-link
                      class="items-table__link"
                      v-bind:to="{
                        name: 'productEdit',
                        params: { id: product.id, locale: product.locale },
                      }"
                      >{{ product.name }}
                    </router-link>
                  </td>
                  <td class="items-table__td">
                    <div class="form-box__mobile-element">Alerty</div>
                    <AlertData :product="product"></AlertData>
                  </td>
                  <td class="items-table__td text-center">
                    <div class="form-box__mobile-element">Warianty</div>
                    {{
                      product.have_variants ? product.variants.length : "nd."
                    }}
                  </td>
                  <td class="items-table__td items-table__td--not-text">
                    <div class="form-box__mobile-element">Status synch.</div>
                    <div
                      :class="[
                        'm-auto',
                        'items-table__status',
                        'items-table__status--' + wooSyncStatus(product),
                      ]"
                    ></div>
                  </td>

                  <td class="items-table__td items-table__td--nowrap">
                    <div class="form-box__mobile-element">Modyfikacja</div>
                    {{ product.updated_at | prettyDate }}
                  </td>

                  <td class="items-table__td">
                    <div class="form-box__mobile-element"></div>
                    <ul class="lang-list">
                      <template v-for="lang in product.all_languages">
                        <li
                          v-if="!lang.id"
                          :key="lang.locale"
                          class="lang-list__item"
                        >
                          <a
                            class="lang-list__link"
                            @click.prevent="
                              editOrCreateLang(lang, 'addTranslation', product)
                            "
                          >
                            {{ lang.locale }}
                          </a>
                        </li>

                        <li
                          v-else
                          :key="lang.locale"
                          class="lang-list__item lang-list__item--exist"
                        >
                          <a
                            class="lang-list__link"
                            @click.prevent="
                              editOrCreateLang(lang, 'productEdit', product)
                            "
                          >
                            {{ lang.locale }}
                          </a>
                        </li>
                      </template>
                    </ul>
                  </td>

                  <td class="items-table__td pr-0">
                    <div class="form-box__buttons">
                      <div
                        class="button-icon button-icon--ml-10"
                        @click="syncProduct(product)"
                        v-if="wooSyncStatus(product) == 'default'"
                        data-tooltip="Synchronizacja"
                      >
                        <icon icon="reverse" icon-classes="icon--grey" />
                      </div>
                      <router-link
                        :to="{
                          name: 'productEdit',
                          params: { id: product.id, locale: product.locale },
                        }"
                        class="button-icon button-icon--ml-10"
                        data-tooltip="Edycja"
                      >
                        <icon icon="edit" icon-classes="icon--grey" />
                      </router-link>

                      <div
                        class="button-icon button-icon--ml-10"
                        @click="deleteProduct(product)"
                        data-tooltip="Usuwanie"
                      >
                        <icon icon="trash" icon-classes="icon--grey" />
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <!-- end of item -->
            </tbody>
          </table>
        </div>
        <!-- // Products list-->
        <div v-else class="content">Brak wyników.</div>
      </div>
      <!-- Pagination -->
      <pagination
        :pagination="products"
        @paginate="getProducts()"
        :offset="1"
      ></pagination>
      <!-- Pagination -->
    </div>

    <!-- Modal delete product -->
    <modal :show="showModal" size="md" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        Usuwanie wariantu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz usunąć produkt
          <strong>{{
            productToDelete.symbol + " - " + productToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteProduct()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal sync product -->
    <modal
      :show="showSyncModal"
      size="md"
      @close="showSyncModal = !showSyncModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Synchronizacja wariantu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz zsynchronizaować produkt
          <strong>{{
            productToSync.symbol + " - " + productToSync.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showSyncModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmSyncProduct()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal toggle product status -->
    <modal
      :show="showToggleStatusModal"
      size="md"
      @close="showToggleStatusModal = !showToggleStatusModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Zmiana statusu wariantu/produktu
      </div>

      <div slot="body">
        <div>
          Warianty/produkty, których status zostanie zmieniony:
          <ul
            class="mt-3"
            v-for="(selectedProduct, index) in selectedProducts"
            :key="index"
          >
            <li>ID {{ selectedProduct }}</li>
          </ul>
          <b
            >Czy napewno chcesz zmienić status
            {{ selectedProducts.length }} wariantów/produktów?</b
          >
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showToggleStatusModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="ConfirmToggleStatus()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
  <!-- // Products -->
</template>

<script>
import axios from "axios";
import Icon from "./parts/Icon";
import Pagination from "./parts/Pagination";
import Modal from "./parts/Modals/Modal";
import { wooSyncStatus } from "../helpers/statusWoo";
import { editOrCreateLang } from "../helpers/editOrCreateLang";
import SpimLoader from "./parts/SpimLoader.vue";
import ContentTop from "./parts/ContentTop.vue";
import Header from "./parts/Header.vue";
import Filters from "./parts/Filters.vue";
import AlertData from "./parts/AlertData.vue";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      isLoading: true,
      products: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1,
        data: {},
      },
      message: "",
      languages: [],
      order: "desc",
      orderBy: "created_at",
      showModal: false,
      showSyncModal: false,
      showToggleStatusModal: false,
      productToSync: {},
      productToDelete: {},
      showFilters: false,
      dictionaries: {},
      filters: {},
      search: "",
      selectedProducts: [],
    };
  },
  components: {
    Pagination,
    Modal,
    Icon,
    SpimLoader,
    ContentTop,
    Header,
    Filters,
    AlertData,
  },
  computed: {
    toggleSelectProducts: {
      get: function () {
        return this.products.data
          ? this.selectedProducts.length == this.products.data.length
          : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.products.data.forEach(function (product) {
            selected.push(product.id);
          });
        }
        this.selectedProducts = selected;
      },
    },
  },
  mounted() {
    this.getProducts();
    this.getLanguages();
    this.getDictionaries();
    this.$root.$on("addProductModalSuccess", () => {
      this.getProducts();
    });
    this.$root.$on("addProductLanguageModalSuccess", () => {
      this.getProducts();
    });
    this.$root.$on("exportProducts", () => {
      this.exportAll();
    });
    this.$root.$on("changeShow", () => {
      this.showFilters = !this.showFilters;
    });
  },
  beforeDestroy() {
    this.$root.$off("addProductModalSuccess");
    this.$root.$off("addProductLanguageModalSuccess");
    this.$root.$off("exportProducts");
    this.$root.$off("changeShow");
  },
  methods: {
    getDictionaries() {
      axios
        .get(API_BASE_URL + "/dictionaries")
        .then((response) => {
          this.dictionaries = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
          this.isLoading = false;
        });
    },
    addProduct() {
      this.$root.$emit("addProductModal", {});
    },
    async getProducts($newPage = null) {
      this.isLoading = true;
      try {
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        var params = {
          page: $newPage ? 1 : this.products.current_page,
          orderBy: this.orderBy,
          order: this.order,
          filters: this.filters,
        };

        if (this.$i18n.locale) params.locale = this.$i18n.locale;
        if (this.search != "") params.search = this.search;

        const response = await axios.get(
          API_BASE_URL + "/products",
          {
            params: params,
          },
          config
        );
        this.products = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async deleteProduct(product) {
      this.showModal = true;
      this.productToDelete = product;
    },
    async syncProduct(product) {
      this.showSyncModal = true;
      this.productToSync = product;
    },
    async confirmDeleteProduct() {
      try {
        await axios.delete(
          API_BASE_URL + "/products/" + this.productToDelete.id
        );
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Usunięto produkt.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
      this.showModal = false;
      this.getProducts();
    },
    async confirmSyncProduct() {
      var data = {
        products: [this.productToSync.id],
      };
      axios
        .post(API_BASE_URL + "/synchronize-woo", data)
        .then(() => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie zsynchronizowano dane w wooCommerce.",
          });
          this.getProducts();
        })
        .catch((error) => {
          this.$root.$emit(
            "noticesBox",
            error.response
              ? error.response.data
              : {
                  type: "error",
                  message: "Wystapił problem przy synchronizacji produktu.",
                }
          );
        });
      this.showSyncModal = false;
    },
    async getLanguages() {
      try {
        const response = await axios.get(API_BASE_URL + "/languages");
        this.languages = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    prepareLanguages(translations) {
      let languages = this.languages;
      let returnedLanguages = {};
      let parsedChilds = {};

      translations.forEach(function (trans) {
        parsedChilds[trans.locale] = trans.product_id;
      });

      languages.forEach(function (lang) {
        returnedLanguages[lang.code] = {
          locale: lang.code,
          default: lang.default,
          product_id: parsedChilds[lang.code],
        };
      });

      return returnedLanguages;
    },
    async toggleStatus() {
      this.showToggleStatusModal = true;
    },
    async ConfirmToggleStatus() {
      try {
        await axios.post(API_BASE_URL + "/products/toggle-status", {
          product_ids: this.selectedProducts,
        });
        this.getProducts();
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Status wariantu został zmieniony.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
      this.showToggleStatusModal = false;
      this.selectedProducts = [];
    },
    sortObjects() {
      event.target.setAttribute("order", this.order == "asc" ? "desc" : "asc");
      this.orderBy = event.target.getAttribute("order-by");
      this.order = event.target.getAttribute("order");

      this.getProducts();
    },
    searchProducts(search) {
      this.search = search;
    },
    editOrCreateLang,
    wooSyncStatus,
    getApiPath(path) {
      if (path) {
        return API_BASE_URL + "/storage/" + path;
      } else return require("../img/default.png");
    },
  },
};
</script>
