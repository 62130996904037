<template>
  <div class="content">
    <Header
      title="Dodaj pliki"
      :subheading="
        onGoingUploads > 0 && countNotCompleted() < 0
          ? `Trwa wysyłanie ${onGoingUploads} ${$options.filters.numeralsText(
              onGoingUploads,
              'pliku',
              'plików',
              'plików'
            )}...`
          : countNotCompleted() > 0
          ? `${$options.filters.numeralsText(
              countNotCompleted,
              'plik gotowy',
              'pliki gotowe',
              'pliki gotowe'
            )}`
          : 'Wybierz pliki do wysłania'
      "
    ></Header>
    <div class="content__body">
      <ContentTop
        :addButton="true"
        :addAdditionalButton="true"
        :textAdditionalButton="onGoingUploads > 0 ? 'Zapisuję' : 'Zapisz'"
        :disabledAdditionalButton="
          onGoingUploads > 0 || forms.length <= 0 || countNotCompleted() == 0
        "
        @addObject="$refs.fileUpload.click()"
        @addAdditionalAction="upload()"
      ></ContentTop>
      <div id="files-upload">
        <div class="row" v-if="forms.length > 0">
          <div class="col-24">
            <div
              class="files-upload"
              :class="{ 'is-dragover': isDragOver }"
              @dragenter="isDragOver = true"
              @dragleave="isDragOver = false"
              @drop.prevent="addFile"
              @dragover.prevent
            >
              <div
                class="row file-form"
                :class="{ 'is-completed': form.completed }"
                v-for="(form, index) in forms"
                :key="index"
              >
                <div
                  class="file-form__data col-24 d-flex align-items-center pl-0"
                >
                  <div
                    class="files-upload__icon"
                    :style="{ backgroundImage: 'url(' + form.icon + ')' }"
                  ></div>
                  <span class="file-form__name"
                    >{{ form.file.name }} ({{
                      form.file.size | prettyBytes
                    }})</span
                  >
                </div>
                <div class="file-form__data col-24 col-xl-8">
                  <input
                    v-if="!form.completed"
                    class="file-form__input"
                    :readonly="form.isUploading"
                    :placeholder="
                      form.isUploading ? '' : 'Kliknij aby dodać nazwe...'
                    "
                    v-model="form.display_name"
                  />
                  <div v-else>{{ form.display_name }}</div>
                </div>
                <div class="file-form__data col-24 col-xl-8">
                  <input
                    v-if="!form.completed"
                    class="file-form__input"
                    :readonly="form.isUploading"
                    :placeholder="
                      form.isUploading ? '' : 'Kliknij aby dodać opis...'
                    "
                    v-model="form.description"
                  />
                  <div v-else>{{ form.description }}</div>
                </div>
                <div class="file-form__data col-24 col-xl-4">
                  <treeselect
                    v-if="!form.completed"
                    v-model="form.type"
                    value-format="object"
                    :multiple="false"
                    :options="fileTypes"
                    placeholder=""
                    :normalizer="normalizer"
                    :clearable="false"
                  />
                  <div v-else>{{ form.type.name }}</div>
                </div>
                <div class="file-form__data col-24 col-xl-2 pr-0">
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      v-if="!form.completed"
                      @click="removeForm(form)"
                    >
                      <icon icon="delete"></icon>
                    </button>
                    <button
                      v-else
                      class="button-icon button-icon--success"
                      @click="removeForm(form)"
                    >
                      <icon icon="check"></icon>
                    </button>
                  </div>
                </div>
                <div
                  class="col-24 d-flex file-form__errors pl-0 pr-0"
                  v-if="form.errors.errors"
                >
                  <div
                    class="file-form__error"
                    v-for="(error, index) in form.errors.errors"
                    :key="'error_' + index"
                  >
                    <template v-for="text in error">{{ text }}</template>
                  </div>
                </div>
                <progress
                  class="file-form__progress"
                  max="100"
                  :value.prop="form.progress"
                  :class="'is-' + form.status"
                ></progress>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-24">
            <div
              v-cloak
              class="files-drop-area"
              :class="{ 'is-dragover': isDragOver }"
              @dragenter="isDragOver = true"
              @dragleave="isDragOver = false"
              @drop.prevent="addFile"
              @dragover.prevent
            >
              <div class="files-drop-area__wrap">
                <template v-if="isDragOver">
                  <span class="files-drop-area__msg"
                    >Upuść tutaj, aby dodać plik(i)</span
                  >
                </template>
                <template v-else>
                  <span class="files-drop-area__msg"
                    >Przeciągnij i upuść tutaj<br />lub</span
                  >&nbsp;<span
                    class="files-drop-area__button"
                    @click="$refs.fileUpload.click()"
                    >Wybierz z dysku</span
                  >
                </template>
              </div>
            </div>
          </div>
        </div>

        <input
          class="files-upload__add-files-input"
          ref="fileUpload"
          type="file"
          @change="addFile"
          multiple="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Icon from "./parts/Icon";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Treeselect from "@riophae/vue-treeselect";
import Header from "./parts/Header.vue";
import ContentTop from "./parts/ContentTop.vue";

export default {
  data() {
    return {
      files: [],
      fileTypes: [],
      forms: [],
      isDragOver: false,
      disableUpload: true,
      onGoingUploads: false,
      uploadCompleted: false,
      selectedForm: {},
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  components: {
    Icon,
    Treeselect,
    Header,
    ContentTop,
  },
  watch: {
    onGoingUploads: {
      handler(newVal) {
        if (newVal == 0) {
          this.uploadCompleted = true;
          this.disableUpload = false;
        }
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.getFileTypes();
  },
  methods: {
    addFile(e) {
      let vm = this;
      vm.disableUpload = false;
      var selectedFiles = e.target.files || e.dataTransfer.files;
      if (!selectedFiles) return;
      vm.isDragOver = false;

      [...selectedFiles].forEach((f) => {
        let preparedForm = {
          name: f.name,
          completed: false,
          description: "",
          type: this.getType(f.name),
          file: f,
          icon: require("../iconfont/default-file-icon.svg"),
          progress: 0,
          status: "default",
          isUploading: false,
          errors: {},
        };
        vm.forms.push(preparedForm);
      });

      vm.$refs.fileUpload.value = "";
    },
    async getFileTypes() {
      this.response = {};
      try {
        const response = await axios.get(API_BASE_URL + "/file-types");
        this.fileTypes = response.data;
      } catch (e) {
        this.response = e.response.data;
      }
    },
    removeForm(form) {
      this.forms = this.forms.filter((f) => {
        return f != form;
      });
    },
    async upload() {
      let vm = this;
      vm.disableUpload = true;
      vm.onGoingUploads = this.countNotCompleted();

      vm.forms.forEach((form) => {
        let thisForm = form;

        if (form.completed) return;

        thisForm.errors = {};
        thisForm.status = "default";

        let formData = new FormData();
        formData.append("file", form.file);
        if (form.display_name) {
          formData.append("display_name", form.display_name);
        }
        formData.append("description", form.description);
        if (form.type) {
          formData.append("type_id", form.type.id);
        }
        thisForm.isUploading = true;

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function(progressEvent) {
            form.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(vm),
        };

        axios
          .post(API_BASE_URL + "/files/upload", formData, config)
          .then(function() {
            thisForm.completed = true;
            vm.onGoingUploads--;
            thisForm.isUploading = false;
            thisForm.status = "success";
          })
          .catch(function(error) {
            thisForm.errors = error.response.data;
            vm.onGoingUploads--;
            thisForm.isUploading = false;
            thisForm.status = "error";
          });
      });
    },
    countNotCompleted() {
      let count = 0;
      this.forms.forEach(function(form) {
        if (!form.completed) count++;
      });

      return count;
    },
    getType(link) {
      let extension = link.substring(link.lastIndexOf(".") + 1);
      return this.fileTypes.find((fileType) => {
        return fileType.ext == extension.toLowerCase();
      });
    },
  },
};
</script>
