<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/markets"
      title="Lista rynków"
      :numeral_text="['rynek', 'rynki', 'rynków']"
      :columns_title="['name', 'url_api']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
