<template>
  <div>
    <content-top
      v-show="objects.length"
      :title="title"
      :subheading="`${objects.length} 
          ${$options.filters.numeralsText(
            objects.length,
            numeral_text[0],
            numeral_text[1],
            numeral_text[2]
          )}`"
      :addButton="true"
      @addObject="addObject"
    ></content-top>
    <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
      <spim-loader></spim-loader>
      <div class="content col-24">
        <table class="items-table items-table--shrink">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th items-table__th--sm">ID</th>
              <th class="items-table__th" v-if="columns_title.includes('name')">
                Nazwa
              </th>
              <th class="items-table__th" v-if="columns_title.includes('icon')">
                Ikona
              </th>
              <th class="items-table__th" v-if="columns_title.includes('ext')">
                Rozszerzenie
              </th>
              <th class="items-table__th" v-if="columns_title.includes('code')">
                Kod
              </th>
              <th
                class="items-table__th"
                v-if="columns_title.includes('currency')"
              >
                Waluta
              </th>
              <th
                class="items-table__th"
                v-if="columns_title.includes('default')"
              >
                Domyślny
              </th>
              <th
                class="items-table__th"
                v-if="columns_title.includes('url_api')"
              >
                Url
              </th>
              <th class="items-table__th p-0 text-right">Akcje</th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td items-table__td--sm">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>
                <td
                  class="items-table__td"
                  v-if="columns_title.includes('name')"
                >
                  <div class="form-box__mobile-element">Nazwa</div>
                  {{ object.name }}
                </td>

                <td
                  class="items-table__td items-table__td--icon"
                  v-if="columns_title.includes('icon')"
                >
                  <img
                    class="items-table__icon"
                    :src="getApiPath(object.icon)"
                  />
                </td>
                <td
                  class="items-table__td"
                  v-if="columns_title.includes('ext')"
                >
                  <div class="form-box__mobile-element">Rozszerzenie</div>
                  {{ object.ext | lowercase }}
                </td>
                <td
                  class="items-table__td"
                  v-if="columns_title.includes('code')"
                >
                  <div class="form-box__mobile-element">Kod</div>
                  {{ object.code | uppercase }}
                </td>
                <td
                  class="items-table__td"
                  v-if="columns_title.includes('currency')"
                >
                  <div class="form-box__mobile-element">Waluta</div>
                  {{ object.currency | uppercase }}
                </td>

                <td
                  class="items-table__td"
                  v-if="columns_title.includes('default')"
                >
                  <div class="form-box__mobile-element">Domyślny</div>
                  <div class="d-flex">
                    <div
                      :class="[
                        'items-table__status',
                        {
                          'items-table__status--default': object.default == '1',
                        },
                      ]"
                    ></div>
                  </div>
                </td>
                <td
                  class="items-table__td"
                  v-if="columns_title.includes('url_api')"
                >
                  <div class="form-box__mobile-element">Url</div>
                  {{ object.url_api }}
                </td>

                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                      data-tooltip="Edytuj"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                      data-tooltip="Usuń"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie elementu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz usunąć ten element
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal edit -->
    <modal
      :show="showEditAddModal"
      size="lg"
      @close="showEditAddModal = !showEditAddModal"
    >
      <div slot="header" class="notice-modal__header--title">
        {{ addEditObject.id ? "Edycja elementu" : "Dodawanie elementu" }}
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24" v-if="columns_title.includes('name')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Nazwa:
              </label>
              <input v-model="addEditObject.name" class="form-box__input" />
            </div>
          </div>
          <div class="col-24" v-if="columns_title.includes('icon')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Ikona:
              </label>
              <input
                type="file"
                ref="photosInput"
                v-on:input="onImageChange($event)"
                style="display: none"
              />

              <button
                class="button button--icon mb-3"
                @click="$refs.photosInput.click()"
                v-if="addEditObject.icon == null"
              >
                <icon
                  icon="plus"
                  icon-classes="icon--white button__icon button__icon--left"
                >
                </icon>
                <span class="button__text">Dodaj zdjęcie</span>
              </button>

              <div class="product-photo mb-0" v-if="addEditObject.icon">
                <img
                  :src="getApiPath(addEditObject.icon)"
                  class="product-photo__img"
                />
                <div
                  class="product-photo__actions d-flex align-items-center justify-content-end"
                >
                  <button
                    class="button-icon button-icon--ml-10 button-icon--bg-white"
                    @click="deleteImage()"
                  >
                    <icon icon="trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-24" v-if="columns_title.includes('ext')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Rozszerzenie:
              </label>
              <input v-model="addEditObject.ext" class="form-box__input" />
            </div>
          </div>
          <div class="col-24" v-if="columns_title.includes('code')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt"> Kod: </label>
              <input
                v-model="addEditObject.code"
                class="form-box__input"
                maxlength="2"
                :disabled="addEditObject.id ? true : false"
              />
            </div>
          </div>
          <div class="col-24" v-if="columns_title.includes('currency')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Waluta:
              </label>
              <input
                v-model="addEditObject.currency"
                class="form-box__input"
                maxlength="3"
              />
            </div>
          </div>
          <div class="col-24" v-if="columns_title.includes('url_api')">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt"> Url: </label>
              <input v-model="addEditObject.url_api" class="form-box__input" />
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--ml-10"
          @click="
            addEditObject.id
              ? updateObject(addEditObject)
              : createObject(addEditObject)
          "
        >
          Zapisz
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modals/Modal";
import SpimLoader from "../parts/SpimLoader.vue";
import ContentTop from "../parts/ContentTop.vue";

export default {
  data() {
    return {
      isLoading: true,
      objects: {},
      showDeleteModal: false,
      showEditAddModal: false,
      addEditObject: {},
      objectToDelete: {},
    };
  },
  components: {
    Icon,
    Modal,
    SpimLoader,
    ContentTop,
  },
  props: {
    dictionary_link: String,
    title: String,
    numeral_text: Array,
    columns_title: {
      type: Array,
      required: true,
    },
  },
  async created() {
    this.getObjects();
  },
  methods: {
    deleteImage() {
      this.addEditObject.file = null;
      this.addEditObject.icon = null;
    },
    onImageChange() {
      this.addEditObject.file = event.target.files[0];
      let url = URL.createObjectURL(this.addEditObject.file);
      this.addEditObject.icon = url;
      console.log(this.addEditObject.icon);
    },
    async createObject(data) {
      let vm = this;
      try {
        var config = {
          headers: {},
        };

        let formData = data;
        if (this.columns_title.includes("icon")) {
          config.headers = {
            "content-type": "multipart/form-data",
          };

          let formData = new FormData();
          if (data.file) {
            formData.append("file", data.file);
          }
          if (data.name) {
            formData.append("name", data.name);
          }
          if (data.ext) {
            formData.append("ext", data.ext);
          }
        }
        const response = await axios.post(
          API_BASE_URL + this.dictionary_link,
          formData,
          config
        );
        vm.objects = response.data;
        this.addEditObject = {};
        this.showEditAddModal = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Poprawnie dodano atrybut.",
        });
      } catch (error) {
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async updateObject(data) {
      try {
        if (this.columns_title.includes("icon")) {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          let formData = new FormData();
          if (data.file) {
            formData.append("file", data.file);
          }
          if (data.name) {
            formData.append("name", data.name);
          }
          if (data.ext) {
            formData.append("ext", data.ext);
          }
          const response = await axios.post(
            API_BASE_URL + this.dictionary_link + "/" + data.id,
            formData,
            config
          );
          this.objects = response.data;
        } else {
          const response = await axios.put(
            API_BASE_URL + this.dictionary_link + "/" + data.id,
            data
          );
          this.objects = response.data;
        }

        this.addEditObject = {};
        this.showEditAddModal = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Poprawnie zedytowano atrybut.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      this.isLoading = true;
      try {
        const response = await axios.get(API_BASE_URL + this.dictionary_link);
        this.objects = response.data;
        console.log(this.objects);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + this.dictionary_link + "/" + this.objectToDelete.id
        );
        this.getObjects();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.showEditAddModal = true;
      this.addEditObject = { ...object };
    },
    addObject() {
      if (this.columns_title.includes("icon")) {
        this.addEditObject = { icon: null };
      } else {
        this.addEditObject = {};
      }
      this.showEditAddModal = true;
    },
    getApiPath(path) {
      if (path && path.includes("blob:http")) {
        return path;
      }
      if (path) {
        let timestamp = new Date().getTime();
        return API_BASE_URL + "/" + path + "?" + timestamp;
      } else return require("../../iconfont/default-file-icon.svg");
    },
  },
};
</script>
