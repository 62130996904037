<template>
  <div class="product-form__input-wrap">
    <div class="product-form__title">{{ label }}</div>
    <Editor
      :object="object"
      :fieldName="fieldName"
      :readonly="readonly"
      :hasExtensions="hasExtensions"
      :productChange="productChange"
      @changes="changeVaule"
    ></Editor>
  </div>
</template>
<script>
import Editor from "../Editor.vue";

export default {
  data() {
    return {};
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasExtensions: {
      type: Boolean,
      default: true,
    },
    productChange: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Editor,
  },
  methods: {
    changeVaule() {
      this.$emit("changes", this.fieldName, this.object);
    },
  },
};
</script>
