<template>
  <div class="product-form product-form--basic-info row">
    <div class="col-24">
      <div class="row">
        <div class="col-24">
          <div class="col-24">
            <InputTreeselect
              :options="dictionaries.spim_categories"
              :object="product"
              :isLoading="isLoading"
              :disabled="product.locale != 'pl' ? true : false"
              fieldName="spim_category_id"
              label="Kategoria SPIM*"
              @additionalFunction="changeSpimCategory"
            ></InputTreeselect>
          </div>
        </div>
        <RolledSection>
          <div slot="header">Lista atrybutów</div>
          <div slot="body" class="row">
            <div class="col-24" v-if="product.attributes.length">
              <table class="items-table">
                <thead>
                  <tr class="items-table__tr">
                    <th class="items-table__th">nazwa</th>
                    <th class="items-table__th items-table__th--w30">
                      wartość
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="items-table__tr"
                    v-for="attr in product.attributes"
                    :key="attr.id"
                  >
                    <td
                      class="items-table__td items-table__td--label"
                      v-if="
                        !product.have_variants ||
                        (!attr.show_in_variants && product.have_variants)
                      "
                    >
                      <div class="form-box__mobile-element">Nazwa</div>
                      {{ attr.name }}
                    </td>
                    <td
                      class="items-table__td items-table__th--w30"
                      v-if="
                        !product.have_variants ||
                        (!attr.show_in_variants && product.have_variants)
                      "
                    >
                      <div class="form-box__mobile-element">Wartość</div>
                      <input
                        v-if="attr.field_type == 'string'"
                        type="text"
                        class="items-table__input items-table__input--xl"
                        v-model="attr.option_value_string"
                        @change="$root.$emit('productIsChanged')"
                      />
                      <label
                        v-else-if="attr.field_type == 'boolean'"
                        :for="'check-attr-' + attr.id"
                        class="product-form__checkbox"
                      >
                        <input
                          :id="'check-attr-' + attr.id"
                          class="product-form__checkbox--input"
                          type="checkbox"
                          v-model="attr.option_value_boolean"
                          @change="$root.$emit('productIsChanged')"
                        />
                        <span
                          class="product-form__checkbox-checkmark"
                          v-text="attr.option_value_boolean ? 'Tak' : 'Nie'"
                        ></span>
                      </label>
                      <label
                        v-else-if="attr.field_type == 'select'"
                        class="mb-0"
                      >
                        <treeselect
                          v-if="attr.options && attr.options.length > 0"
                          class="vue-treeselect"
                          v-model="attr.option_value_ids"
                          :multiple="attr.multi_select"
                          :options="attr.options"
                          placeholder=""
                          :normalizer="normalizer"
                          :clearable="true"
                          @input="$root.$emit('productIsChanged')"
                        />
                        <div
                          v-else
                          class="product-form__note"
                          v-text="
                            isLoading
                              ? 'Ładowanie...'
                              : 'Nie dodano jeszcze opcji do tego atrybutu.'
                          "
                        ></div>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-24" v-else>
              Aby wyswietlić atrybuty przypisz je do kategorii a następnie
              przypisz kategorię do warinatu.
            </div>
          </div></RolledSection
        >
      </div>
    </div>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import InputTreeselect from "./forms/InputTreeselect.vue";
import RolledSection from "./forms/RolledSection.vue";

export default {
  data() {
    return {
      isLoading: false,
      attributes: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.subcategories,
        };
      },
    };
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    dictionaries: {
      type: Object,
      required: false,
    },
  },
  components: { Treeselect, InputTreeselect, RolledSection },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
  },
  methods: {
    changeSpimCategory() {
      this.$emit("changeSpimCategory", true, true);
    },
  },
};
</script>
